import React, { Component } from 'react'
import { Link } from 'gatsby'

import { news } from '../../../config/navigation'

import { Container } from '../../components/elements'
import Page from '../../components/Page'
import SEO from '../../components/SEO'
import Schema from '../../components/Schema'
import NewsArticleLink from '../../components/articles/Listings/NewsArticleLink'
import Pagination from '../../components/articles/Listings/Pagination'
import Newsletter from '../../components/Newsletter'

const renderArticleLink = (article, i, currentPage, feature) => {
  if (feature === true && currentPage === 1) {
    if (i === 0) {
      return (
        <div
          key={article.slug}
          className={`col-12 ${i === 0 ? 'col-lg-8' : 'col-lg-4'}`}
        >
          <NewsArticleLink size={`lg`} article={article} />
        </div>
      )
    } else if ([2, 3, 4, 5].includes(i)) {
      return (
        <div key={article.slug} className="col-6 col-lg-3">
          <NewsArticleLink size={`sm`} article={article} />
        </div>
      )
    }
  }

  return (
    <div key={article.slug} className="col-6 col-lg-4">
      <NewsArticleLink size={`md`} article={article} />
    </div>
  )
}

export default class ListTemplate extends Component {
  render() {
    const {
      articles,
      totalPages,
      currentPage,
      baseUri,
      feature,
    } = this.props.pageContext

    let seoTitle = 'The latest news from Erudus'

    const breadcrumbs = [
      {
        name: 'News',
        uri: '/news',
      },
    ]

    switch (baseUri) {
      case '/news/erudus':
        seoTitle = 'Erudus News, all the latest updates'
        breadcrumbs.push({
          name: 'Erudus News',
          uri: baseUri,
        })
        break
      case '/news/industry':
        seoTitle =
          'Industry News - all the latest industry updates, covered by Erudus'
        breadcrumbs.push({
          name: 'Industry News',
          uri: baseUri,
        })
        break
      case '/news/data-pool':
        seoTitle = 'Data Pool Updates'
        breadcrumbs.push({
          name: 'Erudus Data Pool Updates',
          uri: baseUri,
        })
        break
      case '/news/podcast':
        seoTitle = 'The Erudus Podcast, listen to all the episodes here'
        breadcrumbs.push({
          name: 'The Erudus Podcast',
          uri: baseUri,
        })
        break
      case '/news/release-notes':
        seoTitle = 'Erudus Release Notes'
        breadcrumbs.push({
          name: 'Release Notes',
          uri: baseUri,
        })
        break
      case '/news/you-should-know':
        seoTitle =
          'You should know… our series covering everything Erudus can do'
        breadcrumbs.push({
          name: 'You Should Know',
          uri: baseUri,
        })
        break
    }

    return (
      <Page className="p-posts p-posts--news has-fingerPrints">
        <SEO title={seoTitle} />
        <Schema breadcrumbs={breadcrumbs} />

        <section id="news" className="c-postsHeader c-postsHeader--news">
          <Container>
            <div className="c-postsHeader__inner row">
              <div className="c-postsHeader__title col-md-2">
                <h1>News</h1>
              </div>
              <div className="c-postsHeader__text col-md-10">
                All the latest news from Erudus,{' '}
                <strong>curated for the food industry</strong>
              </div>
            </div>
            <div className="c-postsHeaderMenuWrapper">
              <div className="form-group pb-5 d-block d-xl-none">
                <select
                  value={baseUri}
                  onChange={(event) => (window.location = event.target.value)}
                  className="form-control"
                >
                  <option value="/news">All News</option>
                  {news.map((link) => (
                    <option key={`${link.name}_mobile`} value={link.to}>
                      {link.name}
                    </option>
                  ))}
                </select>
              </div>

              <ul className="c-postsHeaderMenu d-none d-xl-flex">
                <li className={baseUri === '/news' ? 'is-active' : ''}>
                  <Link to="/news" title="All News">
                    All News
                  </Link>
                </li>
                {news.map((link) => (
                  <li
                    key={`${link.name}_tab`}
                    className={baseUri === link.to ? 'is-active' : ''}
                  >
                    <Link to={link.to} title={link.name}>
                      {link.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </Container>
        </section>

        <section id="latest-news" className="c-latestNews">
          <Container>
            <div className="text-right py-4">
              <Link to="/search" className="c-postReturnLink">
                {/* <img src="/icons/arrow-right.svg" class="c-icon mr-2" /> */}
                <span className="c-postReturnLink__text">Search</span>
              </Link>
            </div>
            <div className="c-latestNews__inner pt-2">
              <div className="c-latestNews__items row">
                {articles.map((article, i) =>
                  renderArticleLink(article, i, currentPage, feature)
                )}
              </div>
            </div>
          </Container>
        </section>

        <section className="c-section bg-white">
          <Container className="text-center">
            {totalPages > 1 && (
              <Pagination
                totalPages={totalPages}
                currentPage={currentPage}
                baseUri={baseUri}
              />
            )}
          </Container>
        </section>

        <Newsletter />
      </Page>
    )
  }
}
